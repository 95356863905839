import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../../config';
import './MarkdownViewer.css';

function MarkdownViewer() {
  const [content, setContent] = useState('');
  const { filename } = useParams();

  useEffect(() => {
    console.log(`Fetching document: ${API_URL}/api/docs/${filename}`);
    fetch(`${API_URL}/api/docs/${filename}`)
      .then(response => {
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Received data:', data);
        if (data.content) {
          console.log('Content preview:', data.content.substring(0, 100) + '...');
          setContent(data.content);
        } else {
          throw new Error('No content in response');
        }
      })
      .catch(error => {
        console.error('Error fetching document:', error);
        setContent('加载文档时出错: ' + error.message);
      });
  }, [filename, API_URL]);

  return (
    <div className="markdown-viewer">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
}

export default MarkdownViewer;