import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SoftwareGrid.css';

const products = [
  { name: '个人网站', icon: '🌐' },
  { name: '博客平台', icon: '📝' },
  { name: '盈利能力分析', icon: '📊' },
  { name: '移动应用', icon: '📱' },
  { name: 'AI助手', icon: '🤖' },
  { name: '数据分析', icon: '📊' },
  { name: '学习资源', icon: '📚' },
  { name: '开源项目', icon: '🔓' },
  { name: '社区论坛', icon: '💬' },
];

function SoftwareGrid() {
  const navigate = useNavigate();

  const handleItemClick = (name) => {
    if (name === '盈利能力分析') {
      navigate('/profitability-analysis');
    }
  };

  return (
    <div className="software-grid-container">
      <div className="software-grid">
        {products.map((product, index) => (
          <div key={index} className="software-item" onClick={() => handleItemClick(product.name)}>
            <div className="software-icon">{product.icon}</div>
            <h3>{product.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SoftwareGrid;