import React, { useState } from 'react';

function ResultItem({ label, value, unit, formula, realData }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="pa-result-item">
      <div className="pa-result-header" onClick={() => setIsExpanded(!isExpanded)}>
        <span className="pa-result-label">{label}:</span>
        <span className="pa-result-value">
          {value !== null ? `${value.toFixed(2)}${unit}` : '数据不可用'}
        </span>
        <span className={`pa-expand-icon ${isExpanded ? 'expanded' : ''}`}>▼</span>
      </div>
      {isExpanded && (
        <div className="pa-result-details">
          <p><strong>计算公式:</strong> {formula}</p>
          <p><strong>真实数据:</strong> {JSON.stringify(realData)}</p>
        </div>
      )}
      <hr className="pa-result-divider" />
    </div>
  );
}

export default ResultItem;