import React from 'react';
import './CustomerSupport.css';

function CustomerSupport() {
  return (
    <div className="customer-support">
      <div className="support-item">
        <div className="support-icon">📧</div>
        <div className="support-text">
          <p>有问题或建议？</p>
          <a href="#">联系我</a>
        </div>
      </div>
      <div className="support-item">
        <div className="support-icon">🌟</div>
        <div className="support-text">
          <p>喜欢我的作品？</p>
          <a href="#">给个Star支持</a>
        </div>
      </div>
    </div>
  );
}

export default CustomerSupport;
