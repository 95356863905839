import React from 'react';

function FooterBottom() {
  return (
    <div className="footer-bottom">
      <p>更多联系方式：<a href="#">发送邮件</a>或<a href="#">社交媒体</a>。</p>
    </div>
  );
}

export default FooterBottom;
